html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  word-break: break-word;
}

a {
  color: inherit;
  text-decoration: none;
}

p {
  margin: 0;
}

* {
  box-sizing: border-box;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: white;
    background: black;
  }
}

.contact-zalo {
  position: absolute;
  width: 80px;
  height: 80px;
  top: -20px;
  left: -20px;
  border-radius: 100%;
  border: 2px solid #00aff2;
  animation: contact-zalo-anim 1.2s infinite ease-in-out;
}

@keyframes contact-zalo-anim {
  0% {
    transform: rotate(0) scale(0.5) skew(1deg);
    opacity: 0.2;
  }
  30% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.6;
  }
  100% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.2;
  }
}

.contact-zalo-fill {
  position: absolute;
  width: 60px;
  height: 60px;
  top: -10px;
  left: -10px;
  border-radius: 100%;
  background-color: #00aff2;
  border: 2px solid #00aff2;
  animation: contact-zalo-fill-anim 2.3s infinite ease-in-out;
}

@keyframes contact-zalo-fill-anim {
  0% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.8;
  }
  50% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.5;
  }
  100% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
}
